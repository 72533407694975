import { useEffect, useCallback } from 'react';

import useAccount from '@api/queries/useAccount';
import useVehicle from '@api/queries/useVehicle';
import useScript, { Status } from '@hooks/useScript';
import { useConfig } from '@components/ConfigProvider';
import { useFeatureFlags } from '@components/FeatureFlags';

declare global {
  interface Window {
    embeddedservice_bootstrap: {
      settings: {
        language: string;
        orgId: string;
      };
      prechatAPI: {
        setHiddenPrechatFields: (
          userId: Record<string, string>,
          vin: Record<string, string>,
          oemName: Record<string, string>,
          path: Record<string, string>,
        ) => void;
      };
      userVerificationAPI: {
        clearSession: () => Promise<void>;
      };
      init: (orgId: string, deploymentName: string, url: string, options: Record<string, string>) => void;
    };
  }
}

function LoadSalesforceChat() {
  const config = useConfig();
  const salesforceOrgId = config.get('SALESFORCE_ORG_ID') as string;
  const salesforceUrl = config.get('SALESFORCE_URL') as string;
  const salesforceScrtUrl = config.get('SALESFORCE_SCRT_URL') as string;
  const salesforceLibUrl = config.get('SALESFORCE_LIB_URL') as string;
  const salesforceDeploymentName = config.get('SALESFORCE_DEPLOYMENT_NAME') as string;
  const oemName = config.getOemName();

  const { salesforceChat: isSalesforceChatEnabled } = useFeatureFlags();

  const { data: account } = useAccount();
  const { data: vehicle } = useVehicle();

  const accountId = account?._id || '';
  const vehicleVin = vehicle?.vin || '';

  const scriptUrl = isSalesforceChatEnabled ? salesforceLibUrl : undefined;
  const scriptStatus = useScript(scriptUrl);
  const isScriptReady = scriptStatus === Status.Ready;

  const initSalesforceChat = useCallback(
    (IDMUserId: string, vin: string, oemName: string, path: string) => {
      const isChatInitiated = !!window.embeddedservice_bootstrap?.settings?.orgId;

      if (isChatInitiated && !IDMUserId) {
        console.log('Chat is already initiated and accountId is missing. Skipping initiation.');
        return;
      }

      try {
        const embeddedserviceBootstrap = window.embeddedservice_bootstrap;
        embeddedserviceBootstrap.settings.language = 'en_US';

        window.addEventListener('onEmbeddedMessagingReady', (e) => {
          embeddedserviceBootstrap.prechatAPI.setHiddenPrechatFields({ IDMUserId }, { vin }, { oemName }, { path });
        });

        embeddedserviceBootstrap.init(salesforceOrgId, salesforceDeploymentName, salesforceUrl, {
          scrt2URL: salesforceScrtUrl,
        });

        console.log('Salesforce chat has been initiated successfully');
      } catch (err) {
        console.error('Failed to initiate Salesforce chat', err);
      }
    },
    [salesforceOrgId, salesforceDeploymentName, salesforceUrl, salesforceScrtUrl],
  );

  const resetSalesforceChat = useCallback(async () => {
    try {
      await window.embeddedservice_bootstrap.userVerificationAPI.clearSession();
      console.log('Salesforce chat has been cleared successfully');
    } catch (err) {
      console.error('Failed to clear Salesforce chat session', err);
    }
  }, []);

  useEffect(() => {
    const handleUserChange = async () => {
      if (isSalesforceChatEnabled && isScriptReady) {
        if (accountId) {
          await resetSalesforceChat();
        }
        initSalesforceChat(accountId, vehicleVin, oemName, window.location.pathname);
      }
    };

    handleUserChange();
  }, [isSalesforceChatEnabled, isScriptReady, accountId, vehicleVin, initSalesforceChat, resetSalesforceChat, oemName]);

  return null;
}

export default LoadSalesforceChat;
